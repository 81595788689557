exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/blog-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-blog-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/pages/about/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-page-query-tsx-content-file-path-content-pages-about-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-7-exciting-software-development-principles-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/7-exciting-software-development-principles/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-7-exciting-software-development-principles-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-best-open-source-systems-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/best-open-source-systems/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-best-open-source-systems-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-become-a-web-developer-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/how-to-become-a-web-developer/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-become-a-web-developer-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-deploy-gatsby-to-aws-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/how-to-deploy-gatsby-to-aws/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-deploy-gatsby-to-aws-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-fix-error-function-error-init-failure-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/how-to-fix-error-function-error-init-failure/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-how-to-fix-error-function-error-init-failure-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-javascript-frequently-asked-questions-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/javascript-frequently-asked-questions/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-javascript-frequently-asked-questions-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-mastering-the-coding-challenge-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/mastering-the-coding-challenge/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-mastering-the-coding-challenge-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-problem-solving-tips-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/problem-solving-tips/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-problem-solving-tips-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-programming-interview-questions-and-answers-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/programming-interview-questions-and-answers/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-programming-interview-questions-and-answers-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-programming-language-principles-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/programming-language-principles/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-programming-language-principles-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-python-most-frequently-asked-questions-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/python-most-frequently-asked-questions/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-python-most-frequently-asked-questions-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-software-architecture-patterns-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/software-architecture-patterns/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-software-architecture-patterns-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-top-10-programming-languages-2023-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/top-10-programming-languages-2023/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-top-10-programming-languages-2023-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-vscode-extensions-for-developer-performance-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/post-query.tsx?__contentFilePath=/home/runner/work/codesavy.com/codesavy.com/content/posts/vscode-extensions-for-developer-performance/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-post-query-tsx-content-file-path-content-posts-vscode-extensions-for-developer-performance-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tag-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tag-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-minimal-blog-core/src/templates/tags-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-minimal-blog-core-src-templates-tags-query-tsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */)
}

